import "./notFound.scss";

const NotFound = () => {
  return (
    <div className="notFound">
      404 Not Found <br />
      You are in the wrong place
    </div>
  );
};

export default NotFound;
